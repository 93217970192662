var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical",
    },
    [
      _c("c-tab", {
        ref: "plantDocuTab",
        attrs: {
          type: "vertical",
          height: _vm.tabHeight,
          tabItems: _vm.tabItems,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: { emerActPlanDocu: _vm.emerActPlanDocu },
                  on: {
                    closePopup: _vm.closePopup,
                    transInfo: _vm.transInfo,
                    changeStatus: _vm.changeStatus,
                    getDetail: _vm.getDetail,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }