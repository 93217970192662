<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
    <c-tab
      ref="plantDocuTab"
      type="vertical"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :emerActPlanDocu="emerActPlanDocu"
          @closePopup="closePopup"
          @transInfo="transInfo"
          @changeStatus="changeStatus"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'emergency-action-plan-docu-tab',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopEmergencyPlanId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      emerActPlanDocu: {
        sopEmergencyPlanId: '',
        plantCd: '',
        documentNo: '',
        emergencyPlanName: '',
        emergencyTypeCd: null,
        emergencyClassCd: '',
        damageTypeExpectPeopleNumber: '',
        expectDamageRange: '',
        expectCause: '',
        processActionMatter: '',
        damageTypeEquip: [],
        mdmChemMaterialIds: [],
        emergencyReactNotice: '',
        evacuationProcedures: '',
        evacuationAfterAction: '',
        processAccidents: [],
        useFlag: 'Y',
        regUserId: '',
        chgUserId: '',
      },
      editable: true,
      getUrl: '',
      splitter: 5,
      tab: 'info',
      updateMode: false, 
    };
  },
  computed: {
    tabItems() {
      return [
        // 기본정보
        { name: 'info', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./emergencyActionPlanDocuBase.vue'}`) },
        // 관련 설비 및 물질
        { name: 'equipment', icon: 'groups', label: 'LBL0002612', component: () => import(`${'./emergencyActionRelation.vue'}`), key: uid(), disabled: (this.param.sopEmergencyPlanId ? false : true)  },
        // 관련 사고
        { name: 'accident', icon: 'assessment', label: 'LBL0002613', component: () => import(`${'./emergencyActionAccident.vue'}`), key: uid(), disabled: (this.param.sopEmergencyPlanId ? false : true)  },
        // 비상조치 계획서(상세)
        { name: 'file', icon: 'perm_media', label: 'LBL0002614', component: () => import(`${'./emergencyActionPlanDocuFile.vue'}`), key: uid(), disabled: (this.param.sopEmergencyPlanId ? false : true)  },
      ]
    },
    tabHeight() {
      return String(this.contentHeight - 70) + 'px';
    },
  },
  watch: {
    'emerActPlanDocu'() {
      this.$emit('transInfo', this.emerActPlanDocu);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.eap.planDocu.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.sopEmergencyPlanId) {
        this.$http.url = this.$format(this.getUrl, this.param.sopEmergencyPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.emerActPlanDocu = _result.data;
          this.updateMode = true;
        },);
      } 
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    transInfo(data) {
      this.$emit('transInfo', data);
    },
    changeStatus(data) {
      this.$emit('changeStatus')
      this.param.sopEmergencyPlanId = data;
      this.getDetail();
    },
  }
};
</script>
